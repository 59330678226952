.form__item ion-item {
    border-width: thin;
    --border-color: rgba(var(--ion-text-color-rgb), 0.6);
    --border-radius: 10px;
    --border-width: thin;
    color: rgba(var(--ion-text-color-rgb), 0.6);
    --min-height: 20px;
}

.form__item ion-label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.15px;
}

.form__item ion-item.item-has-focus {
    --border-color: #e8dbf5;
    color: rgba(var(--ion-text-color-rgb), 0.87);
}

.form__item ion-item.item-has-focus ion-label {
    color: #e8dbf5 !important;
}

.form__item ion-item.has-errors {
    --border-color: var(--ion-color-danger) !important;
}

.form__item ion-icon {
    cursor: pointer;
    padding-bottom: 8px;
    height: 100%;
}

.form__item ion-input {
    width: 100%;
}

.form__item {
    max-width: 90%;
    margin: auto auto 10px auto;
}
