.form__error {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.form__error-element {
    height: 10px;
    line-height: 15px;
    white-space: nowrap;
}
