/* @media (prefers-color-scheme: darks) {
    .logo-color {
        fill: white;
  }
    .menuIcon .logo-color {
        fill: #73849a;
  }
}
  @media (prefers-color-scheme: light) {
    .logo-color {
        fill: black;
  }
    .menuIcon .logo-color {
        fill: #616e7e;
  }
} */
.page_bg_white{
  background-color: white;
}
.menuIcon .logo-color {
    fill: #616e7e;
}


@keyframes fadeIn { 
  from { opacity: 0; } 
}

.logo-animate-flicker {
    animation: fadeIn 1s infinite alternate;
}
.landingLogo{
  max-width: 50.77%;
}

.verticalCenter{
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
