.toolbarRightBtn{
    margin-right: 10px;
}
.toolbarRightBtnIcon{
    font-size: 25px;
    margin-left: 5px;
}

.rowPadding{
    margin: 0 15px 0 15px;
}

.rowVerticalPadding{
    padding:25px 0 25px 0;
}

.gridLineBottom{
    border-bottom: 1px solid;
}

.toolbarRightBtnSpin{
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}