.btnGridWrapper{
    width: 90%;
    margin: auto;
}
.btnGridFullWidth{
    width: 100%;
    height: 70px;
}
.text-row{
    padding-left: 10px;
    padding-right: 10px;
}
span.ion-text-left {
    margin-right: auto;
    padding-left: 10px;
    font-size: 20px;
}
.btnGridIconContainer{
    height: 80%;
    width: 56px;
}
.btnGridIconSize{
    width: 30px;
    height: 30px;
    margin-top: 12px;
}