.iconCardBig
{
    font-size: 35px;
}

.display_flex{ 
    display: flex;
}
.badge_warning_match{ 
    margin-right: 10px;
}

.icon_font_16{
    font-size: 16px;
}
.card_animal_header_text{
    font-size: 22px;
}
.card_animal_regular_text{
    font-size: 18px;
    color: black;
}

.extra_margin_row {
    margin-top: 15px;
    margin-bottom: 15px;
}

.card_animal_bold{
    font-weight: bold;
}

.modal_grid_95{
    width: 95%;
}

.modal_grid_padding_sm{
    padding: 10px;
}

.bold_margin_right{
    margin-right: 5px;
}
.bold_margin_left{
    margin-left: 5px;
}
.line_margin_bottom_sm{
    margin-bottom: 5px;
}