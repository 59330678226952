.rowFullWidth{
    width: 95%;
}
.hideBg::part(background) {
  display: none;
}

.hideBg::part(scroll) {
    display: none;
}

.qrCodeBtnForm{
  width: 25px;
  height: 25px;
}

.buttonStopScanQr{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
}
.forceHide{
    display: none !important;
}

.scan-box {
    border: 2px solid #fff;
    box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
    display: block;
    left: 50%;
    height: 70vw;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
  }