.form-text{
    margin-left: 15px;
    
}

.bluetooth-modal{
    --backdrop-opacity: 0.8 !important;
    --border-radius: 20px;
    --height: 300px;
    padding-left: 20px;
    padding-right: 20px;
}
.bluetooth-row__padding-top
{
    padding-top: 20px;;
}
.bluetooth-row__padding-bottom
{
    padding-bottom: 20px;;
}
.modal-segment-margin{
    margin-left: 20px;
    margin-right: 20px;
}

.verde{
    fill: green;
}
.vermelho{
    fill: red;
}
.show{
    display: block;
}
.hide{
    display: none;
}