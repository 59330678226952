.extra-small-text{
    font-size: 10px;
}

.row-animais-lote-saida{
    border: 1px solid #c2c2c2;
    border-radius: 10px;
}

.content-animais-lote-saida{
    height: 30%;    
    border: 1px solid #c2c2c2;
    margin-top: 10px;
    margin-bottom: 10px;
}

.font-20-b{
    font-size: 20px;
    font-weight: bold;
}
.icon-medium{
    font-size: 22px;
}

.m0p0{
    margin: 0;
    padding: 0;
}

.ion-item-no-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
    
  }