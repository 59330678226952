@media (max-width: 800px)
{
    .loginLogo{
        max-width: 45%;
        margin: auto;
    }
    
    .login-header{
        font-weight: bolder;
        font-size: 9vw;
    }
    .login-sub-header{
        font-weight: bolder;
        font-size: 5vw;
    }
    .login-container{
        margin-bottom: 20px;
        margin: auto;
    }
}

@media (min-width: 801px)
{
    .loginLogo{
        max-width: 300px;
        margin: auto;
    }
        
    .login-header{
        font-weight: bolder;
        font-size: 50px;
    }
    .login-sub-header{
        font-weight: bolder;
        font-size: 30px;
    }
    .login-container{
        margin-bottom: 20px;
        max-width: 400px;
        margin: auto;
    }
}

.login-imgage-grid{
    margin-top: 20px;;
    width: 100%;
}
